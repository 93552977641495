
.table-row th,
.table-row td {
  text-align: left;
  padding: 8px;
}

.loading_data {
  text-align: center;
  padding: 20px;
}