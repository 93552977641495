/* Navbar.css */
.navbar {
    background-color: #333;
    color: #fff;
    padding: 15px 0;
    /* background: linear-gradient(178deg, #3c4b64, #a9b4c8d6); */
    background: linear-gradient(181deg, #001452, #00145280)
}

.navbar-container {
    width: 98%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar-logo {
    font-size: 1.5rem;
    width: 15%
}

.navbar-links {
    display: flex;
    /* justify-content: space-between; */
    width: 85%
}

.navbar-links a {
    color: #fff;
    text-decoration: none;
}

.navbar-toggle {
    cursor: pointer;
    display: none;
    font-size: 1.5rem;
}

@media (max-width: 768px) {
    .navbar-links {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        background-color: #333;
    }

    .navbar-links.active {
        display: flex;
    }

    .navbar-toggle {
        display: block;
    }
}

.dropdown {
    display: inline-block;
}

.dropbtn {
    /* background: linear-gradient(45deg, #ff416c, #ff4b2b); */
    color: white;
    /* padding: 16px; */
    font-size: 16px;
    border: none;
    cursor: pointer;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    /* margin-top: 2px */
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    width: 170px;

}

.dropdown-content a:hover {
    background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.pr-15 {
    padding-right: 15px
}