.nav-tabs .nav-item .nav-link.active {
  color: #fff;
  background-color: #3c4b64;
  border-color: #3c4b64;
}
.nav-tabs .nav-link {
  color: #3c4b64;
  font-weight: 700;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  background: #3c4b64;
  border-color: #3c4b64 !important;
  color: #fff;
  border-radius: inherit !important;
}
.nav-tabs .nav-link {
  border-radius: inherit !important;
}


/* finalDetailsForm .css */
.customer-details-form {
  display: flex;
  align-items: center;
  /* margin-top: 100px;  */
  color: #000;
  font-size: 12px;
  flex-direction: column;
}
.heading-div {
  width: 100%;
  background-color: #89BFEB;
  border: "0.01px solid black";
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  font-size: 17px;
}
.container-div {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.title-div {
  background-color: #89BFEB;
  width: '100%';
  border: 1px solid #000;
  font-weight: bold;
  padding-left: 5px;
  font-size: 16px;
}
.ans-div {
  background-color: #fff;
  width: '100%';
  border: 1px solid #000;
  padding-left: 5px;
  font-size: 16px;
}
.centre-div {
  display: flex;
  justify-content: center;
}

.ans-div-capitalize {
  text-transform: capitalize !important;
}

.ans-div-uppercase {
  text-transform: uppercase !important;
}

.rectangle-outline {
  border: 1px solid #3C4B64;
  border-radius: 5px;
  color: #3C4B64;
  padding: 8px;
  font-weight: 700;
  text-align: center;
}

.print-div {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 20px !important;
  font-weight: 700 !important;
}

.red-star {
  color: rgb(255, 0, 0) !important;
}
.centr_align {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.font14 {
  font-size: 16px;
  font-weight: 400;
}
.margin_auto {
  margin: auto;
}

.capitalize-first {
  text-transform: capitalize;
}

.caps-on{
  text-transform: uppercase;
}

.pad_all05{
  padding: 5px !important;
}