body {
  background-color: #ffefff;
  font-family: Arial, sans-serif;
}

.sidebar {
  background-color: #ff0066;
  color: white;
  padding: 20px;
  border-radius: 10px;
}

.sidebar ul {
  padding-left: 0;
}

.sidebar li {
  padding: 10px 0;
}

.sidebar li.active {
  font-weight: bold;
}

.card {
  border-radius: 10px;
}

.btn-calculate {
  background-color: #ff0066;
  border: none;
  color: white;
}

.results {
  margin-top: 20px;
}

.result-card {
  margin-bottom: 10px;
}

.result-card .card-title {
  color: #ff0066;
  font-size: 1.2rem;
  margin-bottom: 0;
}

.result-card .card-text {
  font-size: 1.5rem;
  font-weight: bold;
  color: #ff0066;
}
